
import { mixins, Pie } from 'vue-chartjs'
import { ChartConfiguration, ChartData } from 'chart.js'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const { reactiveProp } = mixins
@Component({
  name: 'PieChart',
  mixins: [reactiveProp],
  extends: Pie
})
export default class extends Vue {
  @Prop(Object) chartData!: ChartData
  @Prop(Object) chartConfig!: ChartConfiguration
  renderChart!: any

  private reDraw() {
    this.renderChart(this.chartData, this.chartConfig)
  }

  private mounted() {
    this.reDraw()
  }

  @Watch('chartData')
  onDataChange() {
    this.reDraw()
  }
}
