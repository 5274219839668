export default class CourseFactory {
  public static emptySubjectModel() {
    return {
      course: {
        id: 0,
        title: '',
        results: [],
        moodleId: 0,
        userId: 0,
        gradeMin: 0,
        gradeMax: 100
      }
    }
  }
}
