
































































import { AgGridVue } from 'ag-grid-vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AgGridFactory from '@/factories/agGridFactory'
import { FormSchema } from 'vue-form-generator'
import PieChartWrapper from '@/components/ui/charts/PieChartWrapper.vue'
import { PieChartCollection } from '@/config/charts/PieChartCollection'
import _ from 'lodash'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import MoodleAPI from '@/api/requests/moodle'
import { GridApi } from 'ag-grid-community'
import ExcelAPI from '@/api/requests/excel'
import { toastMapper } from '@/store/modules/toast'
import MoodleStatisticModal from '@/components/statitics/MoodleStatisticModal.vue'
import CourseFactory from '@/factories/course'
import { moodleStatisticsColors } from '@/config/charts/moodleStatisticsColours'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    PieChartWrapper,
    AgGridVue,
    ActionRenderer,
    MoodleStatisticModal
  }
})
export default class OrganizationStatistic extends Mapper {
  $refs!: {
    moodleStatisticModal: MoodleStatisticModal
  }
  @Prop({ type: String, default: 'Статистика по курсам Moodle' })
  readonly title!: string
  @Prop({ type: Number, default: 0 })
  readonly organizationId!: number
  private rerender = true
  private coursesResults = []
  private COUNT_OF_INTERVALS = 5
  private ranges = []
  private prevIndex = -1
  private modalTitle = ''

  private chartData: PieChartCollection = { labels: [], datasets: [] }
  private chartConfig = {
    legend: {
      position: 'left',
      labels: {
        padding: 20
      }
    },
    responsive: true,
    responsiveAnimationDuration: 2,
    maintainAspectRatio: false,
    onClick: this.filterFromChart
  }

  private subjectModel = CourseFactory.emptySubjectModel()
  private subjectSchema: FormSchema = {
    fields: [
      {
        type: 'multiselect',
        placeholder: 'Выберите курс',
        trackBy: 'id',
        model: 'course',
        customLabel({ title }: any) {
          return title ?? 'Выберите значение'
        },
        multiple: false,
        allowEmpty: false,
        styleClasses: 'wm-100',
        options: [],
        onChanged: this.updateTables
      }
    ]
  }

  private gridApi?: GridApi
  private rowData = []
  private defaultDefs = [
    {
      headerName: '',
      maxWidth: 40,
      field: 'grade',
      colId: 'status',
      filter: 'agNumberColumnFilter',
      cellStyle: (params: any) => {
        if (!_.isNil(params?.value)) {
          const index = Math.floor(params.value / 20)
          return {
            backgroundColor:
              moodleStatisticsColors[
                index === this.COUNT_OF_INTERVALS ? index - 1 : index
              ],
            color: 'transparent'
          }
        }
        return null
      }
    },
    {
      headerName: 'ФИО',
      minWidth: 100,
      field: 'name',
      colId: 'name',
      cellStyle: {
        'text-decoration': 'underline',
        cursor: 'pointer'
      },
      cellClass: 'courseStyle'
    },
    {
      headerName: 'Балл',
      width: 100,
      suppressSizeToFit: true,
      field: 'grade',
      colId: 'grade',
      filter: true
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          onExcel: this.downloadXlsxReport,
          onStatistics: this.onStatisticsModal
        }
      }),
      width: 120
    }
  ]
  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    defaultColDef: {
      editable: false,
      filter: true,
      resizable: true,
      sortable: true
    },
    paginationPageSize: 30,
    onGridReady: this.onGridReady
  }
  private get columnDefs() {
    return this.defaultDefs
  }
  private onGridReady({ api }: { api: any }) {
    this.gridApi = api
  }
  private filterFromChart(evt: any, item: any) {
    const [chartItem] = item
    if (this.prevIndex >= 0 && chartItem._index === this.prevIndex) {
      this.prevIndex = -1
      this.gridApi?.setFilterModel(null)
    } else {
      this.prevIndex = chartItem._index
      const filter = this.ranges[chartItem._index][0] - 1
      const filterTo =
        _.last(this.ranges[chartItem._index]) ===
        this.subjectModel.course.gradeMax - 1
          ? this.subjectModel.course.gradeMax + 1
          : _.last(this.ranges[chartItem._index])
      const filterInstance = this.gridApi?.getFilterInstance('status')
      filterInstance?.setModel({
        filterType: 'number',
        type: 'inRange',
        filter,
        filterTo
      })
    }
    this.gridApi?.onFilterChanged()
  }

  private async created() {
    this.coursesResults = (
      await MoodleAPI.getCourseResultsByCohort(this.organizationId)
    )?.data
    if (!_.isEmpty(this.coursesResults)) {
      this.subjectSchema.fields!.find(
        item => item.model === 'course'
      )!.options = this.coursesResults
      this.subjectModel.course = this.coursesResults[0]
      this.updateTables()
    }
  }

  private updateTables() {
    const stats = new Array(this.COUNT_OF_INTERVALS).fill(0)
    const course = this.subjectModel.course
    const range = Array.from(
      { length: course.gradeMax - course.gradeMin },
      (_, i) => i + course.gradeMin
    )
    const rangeSize =
      (course.gradeMax - course.gradeMin) / this.COUNT_OF_INTERVALS
    this.ranges = _.chunk(range, rangeSize) as any
    const data = course.results?.map(result => {
      const grade = _.last((result as any).gradeItems as any[]).gradeRaw || 0
      for (let i = 0; i < this.ranges.length; i++) {
        const currentRange: any[] = this.ranges[i]
        if (
          currentRange.indexOf(grade as any) > -1 ||
          (grade === course.gradeMax && i + 1 === this.ranges.length)
        ) {
          stats[i] += 1
        }
      }

      return {
        name: (result as any).userFullName,
        grade,
        userId: (result as any).userId,
        moodleId: (result as any).courseId
      }
    })
    this.gridApi?.refreshCells({ columns: ['status'] })
    this.rowData = data as any
    this.chartData = {
      labels: this.ranges.map(
        (val, i) =>
          `${this.ranges[i][0]} - ${
            i === this.COUNT_OF_INTERVALS - 1
              ? _.last(this.ranges[i] as any[]) + 1
              : _.last(this.ranges[i])
          }`
      ),
      datasets: [
        {
          data: stats,
          label: 'Баллы по курсу',
          backgroundColor: moodleStatisticsColors
        }
      ]
    }
    this.rerender = !this.rerender
  }

  private async downloadXlsxReport(params: any) {
    if (params.node.data.userId != null) {
      await ExcelAPI.getCourseResultsByStudent(
        params.node.data.userId,
        params.node.data.moodleId
      )
    } else {
      await this.pushToast({
        error: true,
        title: 'Ошибка',
        message:
          'Данный пользователь Moodle не связан с существующими в системе пользователями',
        time: 5
      })
    }
  }

  private async onStatisticsModal(params: any) {
    if (params.node.data.userId !== null) {
      const model = params.node.data
      model.title = this.subjectModel.course.title
      const courseModel = _.cloneDeep(model)
      this.modalTitle = `Статистика прохождения курса "${this.subjectModel.course.title}"\n Студент: ${params.node.data.name}`
      await this.$refs.moodleStatisticModal.openStatisticModal(courseModel)
      this.$bvModal.show('statisticMoodle')
    } else {
      await this.pushToast({
        error: true,
        title: 'Ошибка',
        message:
          'Данный пользователь Moodle не связан с существующими в системе пользователями',
        time: 5
      })
    }
  }

  private async exportAllCohortResults() {
    await ExcelAPI.getAllResultsByCohort(this.organizationId)
  }

  private async exportCourseCohortResults() {
    await ExcelAPI.getCourseResultsByCohort(
      this.organizationId,
      this.subjectModel.course.id
    )
  }
}
