














import { Vue, Component } from 'vue-property-decorator'
import { dictMapper } from '@/store/modules/dicts'
import ContentViewer from '@/components/content/ContentViewer.vue'
import _ from 'lodash'
import ContentFactory from '@/factories/content'
import { userMapper } from '@/store/modules/user'
import OrganizationStatistic from '@/components/statitics/OrganizationStatistic.vue'
import { typeOrganization } from '@/config/types/typeOrganization'

const Mapper = Vue.extend({
  computed: {
    ...dictMapper.mapGetters(['organizations']),
    ...userMapper.mapGetters([
      'isTeacher',
      'isCurator',
      'isAdmin',
      'userOrganizations'
    ])
  },
  methods: {
    ...dictMapper.mapActions(['fetchAllOrganizations'])
  }
})
@Component({
  components: {
    ContentViewer,
    OrganizationStatistic
  }
})
export default class OrganizationViewer extends Mapper {
  private id: number | string = this.$route.params.id
  private organization: any = ContentFactory.emptyOrganization()
  private isOtherOrgType = false

  private async created() {
    await this.fetchAllOrganizations()
    if (this.id === 'new') {
      this.organization = ContentFactory.emptyOrganization()
    } else {
      this.id = Number(this.id)
      if (!_.isNaN(this.id)) {
        this.organization = this.organizations.find(
          (e: any) => e.id === this.id
        )
        if (_.isNil(this.organization)) {
          await this.$router.replace('/error')
        }
      } else {
        await this.$router.replace('/error')
      }
    }
    this.isOtherOrgType =
      this.organization.organizationType === typeOrganization['Другое']
  }

  private isUsersOrganization() {
    return (
      this.userOrganizations.findIndex(
        (org: { id: string | number }) => org.id === this.id
      ) > -1
    )
  }
}
